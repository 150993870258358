<template>
  <div>
    <!--    公共链接-->
    <el-dialog title="!" :visible.sync="beforeCreateLink" width="40%">
      <div slot="title" class="header-title">
        <div class="header-title-tip">!</div>
        <!--        创建不受限制的公用链接-->
        <span>{{ $t("label.file.public.link.share") }}</span>
      </div>
      <!--      任何拥有该链接的人员均可查看与下载文件。请确认文件不包含敏感信息。请考虑与特定用户或记录共享文件-->
      <span
        >{{ $t("label.file.anyone.can.link") }}。{{
          $t("vue_label_file_safe_type_file")
        }}</span
      >
      <span slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="beforeCreateLink = false">{{
          $t("label.cancel")
        }}</el-button>
        <!--        确定-->
        <el-button type="primary" @click="confirmCreateLink">{{
          $t("label.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!--    公用链接  label.file.publiclink-->
    <el-dialog
      :title="$t('label.file.publiclink')"
      :visible.sync="outerVisible"
    >
      <!--      任何拥有该链接的人员均可查看与下载文件-->
      <p class="public-link-tip">{{ $t("label.file.anyone.can.link") }}</p>
      <div class="create-public-link" v-show="!isCreatedLink">
        <div class="create-public-link-l"></div>
        <!--        创建链接  label.file.create.link-->
        <div class="create-public-link-r" @click="createLink()">
          {{ $t("label.file.create.link") }}
        </div>
      </div>
      <div class="create-public-link" v-show="isCreatedLink">
        <el-input
          disabled
          v-model="publicLink"
          class="create-public-link-l"
        ></el-input>
        <!--        复制链接 label.file.copy.link-->
        <div class="create-public-link-r" @click="copyText(publicLink)">
          {{ $t("label.file.copy.link") }}
        </div>
        <svg
          class="icon public-link-delete"
          aria-hidden="true"
          @click="deleteLink()"
        >
          <use href="#icon-delete"></use>
        </svg>
      </div>
    </el-dialog>
    <!--    删除公用链接 label.file.delete.public.link-->
    <el-dialog
      width="40%"
      :title="$t('label.file.delete.public.link')"
      :visible.sync="innerVisible"
    >
      <!--      删除公用链接后，该地址将作废。重新创建公用链接将生成不同的地址。-->
      <span>{{ $t("label.file.delete.public.link.desc") }}</span>
      <div slot="footer" class="dialog-footer">
        <!--        取消-->
        <el-button @click="innerVisible = false"> </el-button>
        <!--        确认-->
        <el-button type="primary" @click="confirmDeleteLink">{{
          $t("label.confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { copyText } from "@/utils/copyInfo";
import * as fileApi from "../api.js";

export default {
  components: {},
  props: {},
  data() {
    return {
      fileId: "",
      fileItem: "",
      beforeCreateLink: false, // 未创建链接提示
      outerVisible: false, // 公共连接外
      isCreatedLink: false, // 是否创建
      innerVisible: false, // 公共连接内
      publicLink: "", // 链接示例
    };
  },
  mounted() {},
  created() {},
  methods: {
    copyText,
    openPage(obj, id) {
      this.fileItem = obj;
      this.fileId = id;
      this.openLinkDialog();
    },
    // 创建公共链接相关
    createLink() {
      this.beforeCreateLink = true;
    },
    copyLink() {
      // 已复制该公用链接 label.file.copy.public.link
      this.$message({
        showClose: true,
        type: "success",
        message: this.$i18n.t("label.file.copy.public.link"),
      });
    },
    deleteLink() {
      this.innerVisible = true;
    },
    confirmCreateLink() {
      // 请求创建连接接口，给publicLink赋值
      fileApi.createPublicLink({ id: this.fileId }).then(() => {
        this.beforeCreateLink = false;
        this.outerVisible = true;
        this.isCreatedLink = true;
        fileApi
          .getPublicLink({
            id: this.fileId,
          })
          .then((res) => {
            this.publicLink = res.data.publicLink;
          });
      });
    },
    confirmDeleteLink() {
      // 请求删除连接接口，给publicLink置空
      fileApi.delPublicLink({ id: this.fileId }).then(() => {
        this.isCreatedLink = false;
        this.innerVisible = false;
        this.publicLink = "";
      });
    },
    openLinkDialog() {
      fileApi
        .getPublicLink({
          id: this.fileId,
        })
        .then((res) => {
          if (res.data.publicLink) {
            this.publicLink = res.data.publicLink;
            this.outerVisible = true;
            this.isCreatedLink = true;
          } else {
            this.outerVisible = true;
            this.isCreatedLink = false;
          }
        });
    },
  }
};
</script>
<style lang="scss" scoped>
.header-title {
  display: flex;
  align-items: center;

  .header-title-tip {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #888888;
    color: #fffdef;
    margin-right: 5px;
    font-size: 10px;
  }
}

.public-link-tip {
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}

.create-public-link {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  margin-bottom: 50px;

  .create-public-link-l {
    flex-grow: 1;
    display: flex;
    align-items: center;
    //padding: 0 10px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #dddbda;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }

  .create-public-link-r {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #006dcc;
    width: 80px;
    height: 32px;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    cursor: pointer;
  }

  .public-link-delete {
    width: 26px;
    height: 26px;
    margin: 0 10px;
    cursor: pointer;
  }
}
</style>
