import axios from "@/config/httpConfig";

// 上传文件
export function fileUpload(args) {
  return axios.post("file/upload", args);
}

//获取文件大小限制
export function queryLimit(data) {
  return axios.post("/file/queryLimit", data);
}

// 文件保存编辑
export function saveEditFile(args) {
  return axios.post("file/saveEdit", args);
}
//上传文件
export function upload(data, config) {
  return axios.post("/file/upload", data, config);
}

// 文件详情
export function getFileDetail(args) {
  return axios.post("file/detail", args);
}
//文件-获取列表
export function QueryListTab(data) {
  return axios.post("file/queryListForTab", data);
}

// 文件删除
export function deleteFile(args) {
  return axios.post("file/delete", args);
}

// 文件编辑回显
export function editFile(args) {
  return axios.post("file/edit", args);
}
// 文件预览
export function preview(args) {
  return axios.post("file/preview", args);
}

// 禁止共享
export function cannotShareInfo(args) {
  return axios.post("file/cannotShare", args);
}

// 创建公用链接
export function createPublicLink(args) {
  return axios.post("file/createPublicLink", args);
}

// 获取公用链接
export function getPublicLink(args) {
  return axios.post("file/publicLink", args);
}

// 删除公用链接
export function delPublicLink(args) {
  return axios.post("file/deletePublicLink", args);
}

// 文件获取共享角色
export function getShareRoleList(args) {
  return axios.post("file/getShareRole", args);
}

// 获取文件共享信息
export function getShareList(args) {
  return axios.post("file/queryShareInfo", args);
}

// 取消共享
export function cancelShareInfo(args) {
  return axios.post("file/cancelShare", args);
}

// 保存文件共享信息
export function saveShareInfo(args) {
  return axios.post("file/saveShare", args);
}

// 历史版本
export function versionHistory(args) {
  return axios.post("file/versionHistory", args);
}
// 添加追随
export function addFollow(args) {
  return axios.post("file/addfollow", args);
}
// 取消追随
export function cancelFollow(args) {
  return axios.post("file/cancelFollow", args);
}
// 获取追随
export function getFollow(args) {
  return axios.post("file/following", args);
}
// 获取文档库列表
export function getQueryLibs(args) {
  return axios.post("file/queryLibs", args);
}
// 创建文档库
export function createQueryLibs(args) {
  return axios.post("file/createLib", args);
}
// 获取文档库编辑信息
export function getEditQueryLibs(args) {
  return axios.post("file/editLib", args);
}
// 文档库编辑保存
export function saveEditQueryLibs(args) {
  return axios.post("file/saveEditLib", args);
}
// 删除文档库
export function deleteQueryLibs(args) {
  return axios.post("file/deleteLib", args);
}
// 文件库添加文件
export function addFileToLibs(args) {
  return axios.post("file/file2Lib", args);
}
// 保存文件夹
export function saveFolderLibs(args) {
  return axios.post("file/saveFolder", args);
}
// 上传新版本
export function upNewVer(args) {
  return axios.post("file/uploadNewVersion", args);
}
//保存文件库管理
export function saveLibManager(data) {
  return axios.post("/file/saveLibManager", data);
}
//删除文件库管理
export function delLibManager(data) {
  return axios.post("/file/deleteLibManager", data);
}
//查询文件库管理
export function getLibManager(data) {
  return axios.post("/file/queryLibManagerInfo", data);
}

//文件-附件添加Dropbox类型文件
export function getSaveDropbox(data) {
  return axios.post("/file/saveDropbox", data);
}
